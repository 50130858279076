import * as React from "react";

import { Grid, Container, Box, Typography, Divider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

export default function FixedBottomNavigation() {
  return (
    <footer>
      <CssBaseline />
      <Box
        px={{ xs: 3, sm: 5 }}
        py={{ xs: 5, sm: 5 }}
        bgcolor="#111"

        color="white"
        sx={{ borderTop: "solid 1px whitesmoke" }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} >

            <Grid item xs={12} sm={4}>
              <Box borderBottom={1} color="text.secondary">Elérhetőség</Box>
              <Box>
                <Typography
                  color="inherit"
                  sx={{
                    textDecoration: "none",
                  }}
                  component="a"
                  variant="body1"
                  href="tel:+3630-470-0548"
                >
                  <LocalPhoneIcon sx={{
                    mr: 1, pt: 0.8, color: "text.secondary"
                  }} />
                  Telefonszám: +36 30 470 0548
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="inherit"
                  sx={{
                    textDecoration: "none",
                    my: 5,
                  }}
                  component="a"
                  variant="body1"
                  href="mailto: gsd150207@gmail.com"
                >
                  <EmailIcon sx={{
                    mr: 1, pt: 0.8, color: "text.secondary"
                  }} /> E-mail cím:
                  gsd150207@gmail.com

                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1} color="text.secondary">Közösségi média</Box>
              <Box>
                <Typography
                  color="inherit"
                  sx={{
                    textDecoration: "none",
                  }}
                  target="_blank"
                  component="a"
                  variant="body1"
                  href="https://www.facebook.com/profile.php?id=61560684907877&locale=hu_HU"
                >
                  <FacebookIcon
                    sx={{
                      mr: 1,
                      pt: 0.8,
                      color: "text.secondary"
                    }}
                  />
                  Facebook
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="inherit"
                  sx={{
                    textDecoration: "none",
                  }}
                  target="_blank"

                  component="a"
                  variant="body1"
                  href="https://www.instagram.com/gsd-mobilgumi"
                >
                  <InstagramIcon
                    sx={{
                      mr: 1, pt: 0.8, color: "text.secondary"
                    }}
                  />
                  Instagram
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider variant="middle" />
      <Box
        bgcolor="#111"

        sx={{ py: 2 }}
        textAlign="center"
      >
        <Container maxWidth="lg">
          <Box textAlign="center">
            <Typography
              color="white"
              sx={{
                textDecoration: "none",
              }}
              component="a"
              variant="body1"
              href="/impressum"
            >
              Impresszum
            </Typography>{" "}
          </Box>
        </Container>
      </Box>
    </footer>
  );
}