import { Typography, Grid } from "@mui/material";
import PacmanLoader from "react-spinners/PacmanLoader";

export default function Loading() {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        height: "100vh",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#262626"
      }}
    >
      <Grid item>
        <PacmanLoader variant="h2" color="#ff4c4c" size={40} />
        <Typography variant="h3" sx={{color: "text.secondary"}}>Betöltés...</Typography>
      </Grid>
    </Grid>
  );
}
